<script setup lang="ts">
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useProvider } from '/~/composables/provider'
import { useMembership } from '/~/templates/bill-payments/composables'
import { usePointsProgramsComponent } from './points-programs'

const props = withDefaults(
  defineProps<{
    isDrawer?: boolean
    to?: string
  }>(),
  {
    isDrawer: false,
    to: 'modals-portal',
  }
)

const {
  onSubmit,
  isButtonDisabled,
  sortedMemberships,
  pointPayments,
  isActive,
  tooltipOptions,
  membershipsFetching,
  membership,
  isPointProgramsFees,
} = usePointsProgramsComponent(props)

const { taxationLabel } = useProvider()
const { fetchMemberships } = useMembership()

fetchMemberships()
</script>

<template>
  <div class="h-full sm:h-auto">
    <div class="mb-[35px]">
      <div class="py-6 text-center">
        <base-icon
          svg="symbion/token"
          class="mx-auto text-primary"
          :size="70"
        />
      </div>
      <div class="mb-5 text-center">
        <h3 class="mb-5 text-2xl">Choose your points program tier</h3>
        <p class="text-eonx-neutral-600">
          Select your preferred program tier in order to set your points earn
          rate
        </p>
      </div>
    </div>
    <div v-if="membershipsFetching" class="mb-6 h-full w-full pb-6 text-center">
      <base-loader />
    </div>
    <div v-else>
      <div
        class="mx-auto flex items-stretch justify-center gap-[15px]"
        :class="[isDrawer || ui.mobile ? 'flex-col' : 'flex-row']"
      >
        <template v-for="member in sortedMemberships">
          <base-radio
            v-if="member.name && member.name.toLowerCase() !== 'hidden'"
            :key="member.id"
            v-model="membership"
            v-tooltip="tooltipOptions(member)"
            v-analytics:input="{
              pageGroup: isDrawer ? 'Drawer' : 'Home',
              page: 'Program Tier',
              cta: `${member.name} membership`,
            }"
            :value="member"
            :checked="member.isActive"
            hide-input
            centered
            class="ml-0 w-full overflow-hidden !rounded-lg border px-5 py-2.5 hover:!bg-white hover:shadow-ring"
            :class="[
              isActive(member)
                ? 'border-primary-lighten bg-primary-lightest p-[5px] shadow-ring'
                : 'border-disabled bg-white',
            ]"
          >
            <div
              class="flex flex-col items-center whitespace-normal text-center"
            >
              <div
                class="absolute left-0 top-0 h-full border-2 border-l-8"
                :class="[
                  isActive(member) ? 'border-primary' : 'border-disabled',
                ]"
              ></div>
              <div class="text-xl font-extrabold capitalize">
                {{ member.name }}
              </div>
              <div v-if="member.label" class="my-[5px] font-semibold">
                ‘{{ member.label }}’
              </div>
              <div
                v-for="(description, index) of member.description"
                :key="index"
                class="text-xs leading-relaxed text-eonx-neutral-600"
              >
                {{ description }}
              </div>
            </div>
          </base-radio>
        </template>
      </div>
      <div
        class="mt-[15px] py-[15px] text-base font-bold"
        data-testid="processing-fees"
      >
        <span v-if="pointPayments.length">
          Payment processing fees (ex {{ taxationLabel }})
        </span>
      </div>

      <template v-if="pointPayments.length && isPointProgramsFees">
        <div
          v-for="(payment, index) in pointPayments"
          :key="index"
          class="mb-[15px] flex flex-col gap-y-[5px]"
        >
          <div
            class="mb-[5px] flex items-center bg-eonx-neutral-200 px-2.5 py-2.5"
          >
            <span class="flex-1 text-lg font-bold">
              {{ payment.title }}
            </span>
            <base-icon
              v-for="icon in payment.icons"
              :key="icon.title"
              :svg="icon.title"
              size="md"
              class="ml-[5px]"
            />
          </div>
          <div
            v-for="(fee, feeIndex) in payment.fees"
            :key="`${index}${feeIndex}`"
            class="flex justify-between px-2.5"
          >
            <span class="leading-relaxed">{{ fee.title }}</span>
            <span class="text-eonx-neutral-600">
              {{ fee.fee }}
            </span>
          </div>
        </div>
      </template>
    </div>

    <div
      class="sticky -bottom-5 -mx-5 -mb-5 bg-white px-5 pb-5 pt-[15px] text-center shadow-sm sm:shadow-none"
    >
      <base-button
        v-analytics:click="{
          pageGroup: isDrawer ? 'Drawer' : 'Home',
          page: 'Program Tier',
          cta: 'Confirm',
        }"
        :disabled="isButtonDisabled"
        full-width
        type="submit"
        class="w-full"
        @click="onSubmit"
      >
        Confirm
      </base-button>
    </div>
  </div>
</template>
