import { ref, computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { useCms } from '/~/composables/cms/use-cms'
import { useUser } from '/~/composables/user'
import { useMembership } from '/~/templates/bill-payments/composables'
import { BillPaymentsMembership } from '/~/templates/bill-payments/core/membership'

const { pointProgramsFees, isPointProgramsFees } = useCms()
const { user, membershipConfirmed, confirmUserMembership } = useUser()
const { memberships, membershipsFetching, updateMembership } = useMembership()

export function usePointsProgramsComponent(props: { isDrawer: boolean }) {
  const membership = ref(memberships.value.find((m) => m.isActive))

  const sortedMemberships = computed(() => {
    return [...memberships.value].sort(
      (a, b) => b.feePercentage - a.feePercentage
    )
  })

  const isButtonDisabled = computed(() => {
    return (
      membershipsFetching.value ||
      (membership.value?.id === user.value.membershipId &&
        membershipConfirmed.value)
    )
  })
  const activeMembership = computed(() =>
    memberships.value.find((item) => item.isActive)
  )
  const pointPayments = computed(() => {
    return (
      pointProgramsFees.value.find(
        (item) =>
          item.title ===
          (membership.value?.raw?.name || activeMembership.value?.raw?.name)
      )?.payment || []
    )
  })

  function isActive(member: BillPaymentsMembership) {
    if (!membership.value) {
      return member.isActive
    }

    return member.id === membership.value.id
  }

  function tooltipOptions(member: BillPaymentsMembership) {
    return {
      content:
        member.name &&
        `
        <p class="capitalize">${member.name.toLowerCase()} tier</p>
        <p>${member.label}</p>
      `,
      trigger: 'hover click',
      placement: 'top',
      template:
        '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
    }
  }

  async function onSubmit() {
    if (!membership.value) {
      return
    }

    const { name, id } = membership.value

    const options = {
      props: {
        title: `Are you sure you wish to change to the ${name} program tier?`,
        description: `<div class="space-y-6 pr-2">
            <div>This may affect fees, together with your points earn rate.</div>
            <div>Upon confirmation, the platform will refresh, redirecting you to the homepage, and your new program tier will be set.</div></div>`,
        icon: {
          path: 'heroicons/solid/information-circle',
          color: 'text-blue-500',
        },
        width: 'xse',
        showCancel: false,
        analytics: {
          confirm: {
            pageGroup: props?.isDrawer ? 'Drawer' : 'Home',
            page: 'Program Tier',
            component1: 'Confirm Program Tier change',
            cta: 'Confirm',
          },
        },
        confirmLabel: 'Confirm',
        onConfirm: async () => {
          try {
            await updateMembership(id)
            await confirmUserMembership(true)

            location.replace('/')
          } catch (e) {
            console.error(e)
          }
        },
      },
    }

    if (props.isDrawer) {
      bottomSheet.show('confirm', {
        ...options,
        to: !ui.mobile ? 'menu-modal' : undefined,
      })
    } else {
      modal.show('confirm-v2', options)
    }
  }

  return {
    onSubmit,
    isButtonDisabled,
    sortedMemberships,
    pointPayments,
    isActive,
    tooltipOptions,
    membershipsFetching,
    membership,
    memberships,
    isPointProgramsFees,
  }
}
